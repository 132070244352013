<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-file-tree"
      inline
      class="px-5 py-4 ml-0 pb-10"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Logs da importação #{{ importacao.id }}
        </div>
      </template>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            style="float: right !important"
            class="mt-n9"
            v-bind="attrs"
            v-on="on"
          >
            <v-btn
              id="importacao-bdgd-logs-tecnicos"
              icon
              color="black"
              @click="baixarLogsTecnicos()"
              :loading="loadingLogsTecnicos"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="22"> mdi-download-circle-outline </v-icon>
            </v-btn>
          </div>
        </template>
        Clique aqui para baixar os logs técnicos
      </v-tooltip>
      <v-divider class="my-4" />
      <span id="importacao-bdgd-logs-first-sec">
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">Título da importação:</label>
            <v-label>{{ titulo }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">Empresa:</label>
            <v-label>{{ empresa }}</v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">Descrição:</label>
            <v-label>{{ descricao ? descricao : '-' }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">Mês dos dados:</label>
            <v-label>
              {{ dataRegistro | formatToMonth }} v{{ versao }}
            </v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">Criado por:</label>
            <v-label>{{ criado_em_por }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">Codificação dos arquivos:</label>
            <v-label>{{ encoding }}</v-label>
          </v-col>
        </v-row>
      </span>
      <v-row>
        <v-col cols="6">
          <label class="d-block mb-1">Módulo 10 Antigo:</label>
          <v-label>{{ modulo10_antigo ? 'Sim' : 'Não' }}</v-label>
        </v-col>
        <v-col cols="6">
          <label class="d-block mb-1">Logs de validações:</label>
          <v-tooltip
            v-if="log_validacoes_existe"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="importacao-bdgd-logs-valid"
                color="gray"
                outlined
                small
                @click="baixarLogsValidacoes()"
                :loading="loadingLogsValidacoes"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left> mdi-download-circle-outline </v-icon>
                Baixar
              </v-btn>
            </template>
            Clique aqui para baixar os logs de validações
          </v-tooltip>
          <v-label v-else>-</v-label>
        </v-col>
      </v-row>
      <v-divider class="mt-2 mb-2" />
      <v-row v-if="pre_importacao.status">
        <v-col
          md="6"
          cols="12"
        >
          <pre-import-status-card
            id="pre-import-status-card-ib"
            :statusInicial="pre_importacao.status"
            :numeroArquivos="pre_importacao.numero_arquivos"
            :numeroEntidades="pre_importacao.numero_entidades"
            :numeroItensProcessadosInicial="
              pre_importacao.numero_arquivos_processados
            "
            :isPreImport="true"
          />
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <import-status-card
            id="import-status-card-ib"
            :statusInicial="importacao.status"
            :numeroEntidades="importacao.numero_entidades"
            :numeroItensProcessadosInicial="
              importacao.numero_entidades_processados
            "
            :isPreImport="false"
          />
        </v-col>
      </v-row>
      <v-row v-if="!isEmptyObject(arquivos)">
        <v-col
          cols="12"
          class="py-0"
        >
          <entities-import-tab
            :entidadesBdgd="entidades"
            :arquivos="arquivos"
            :contadores="contadores"
            @stepTourSummaryReleased="stepTourSummaryReleased = true"
          />
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import ImportacoesBdgdService from '@/services/ImportacoesBdgdService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [refreshDataMixins, tourMixins],

  components: {
    PreImportStatusCard: () =>
      import('@/components/geracao-bdgd/importacoes-bdgd/ImportStatusCard'),
    ImportStatusCard: () =>
      import('@/components/geracao-bdgd/importacoes-bdgd/ImportStatusCard'),
    EntitiesImportTab: () =>
      import('@/components/geracao-bdgd/importacoes-bdgd/EntitiesImportTab')
  },

  data: () => ({
    empresa: '',
    titulo: '',
    dataRegistro: '',
    versao: 0,
    modulo10_antigo: false,
    descricao: 0,
    encoding: '',
    pre_importacao: {},
    importacao: {},
    entidades: '',
    arquivos: {},
    contadores: {},
    criado_em_por: '',
    log_validacoes_existe: false,
    loadingLogsValidacoes: false,
    loadingLogsTecnicos: false,
    stepTourSummaryReleased: false
  }),

  async created() {
    await this.refreshData(this.getLogsImportacao);
  },

  computed: {
    arrTour() {
      let tour = [
        {
          element: '#importacao-bdgd-logs-first-sec',
          intro:
            'Nessa seção é possivel visualizar as informações basicas da importação.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#importacao-bdgd-logs-tecnicos',
          intro:
            "Clicando neste botão você poderá realizar o download dos log's técnicos da importação, esse log é mais conveniente para desenvolvedores.",
          scrollTo: 'tooltip',
          position: 'bottom'
        }
      ];

      if (this.log_validacoes_existe) {
        tour.push({
          element: '#importacao-bdgd-logs-valid',
          intro:
            "Clicando neste botão você poderá realizar o download dos log's de validação, caso ocorra algum erro ou alerta na etapa de validação será registrado neste arquivo.",
          scrollTo: 'tooltip',
          position: 'top'
        });
      }

      tour.push(
        {
          element: '#pre-import-status-card-ib',
          intro:
            'Aqui é possivel visualizar o status da pre-importação, qtd. total de arquivos e entidades processadas e progresso atual desta etapa.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#import-status-card-ib',
          intro:
            'Aqui é possivel visualizar o status da importação, qtd. total de entidades processadas e progresso atual desta etapa.',
          scrollTo: 'tooltip',
          position: 'bottom'
        }
      );

      if (this.stepTourSummaryReleased) {
        tour.push({
          element: '#import-bdgd-summary',
          intro:
            'Aqui é possivel visualizar um breve resumo do processo de importação.',
          scrollTo: 'tooltip',
          position: 'top'
        });
      }

      tour.push(
        {
          element: '#menu-esquerdo-entidades-ib',
          intro:
            'Aqui ficará listado todas entidades que foram registradas para serem importadas, clicando sobre elas você consegue ver mais detalhes sobre cada uma.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#importacao-bdgd-table-desc-ent',
          intro:
            'Aqui será possivel visualizar os arquivos que foram enviados para compor cada uma das entidades e seu status atual.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#importacao-bdgd-column-df',
          intro:
            'Clicando sobre essa coluna é possivel realizar o download do arquivo, porem caso essa importação tenha sido criada a 60 dias atrás ou mais, não será possível baixar esse arquivo.',
          scrollTo: 'tooltip',
          position: 'right'
        }
      );

      return tour;
    }
  },

  methods: {
    getLogsImportacao() {
      if (!this.$route.params.id) {
        return;
      }

      ImportacoesBdgdService.getLogsImportacao(this.$route.params.id).then(
        (rData) => {
          this.empresa = rData.empresa;
          this.titulo = rData.titulo;
          this.dataRegistro = rData.data_registro;
          this.versao = rData.versao;
          this.modulo10_antigo = rData.modulo10_antigo;
          this.descricao = rData.descricao;
          this.encoding = rData.encoding;
          this.pre_importacao = {
            id: rData.id,
            status: rData.status_pre,
            numero_arquivos: rData.numero_arquivos,
            numero_arquivos_processados: rData.numero_arquivos_processados,
            numero_entidades: rData.numero_entidades
          };
          this.importacao = {
            id: rData.id,
            status: rData.status_importacao,
            numero_entidades: rData.numero_entidades,
            numero_entidades_processados: rData.numero_entidades_processadas
          };
          this.entidades = Object.values(rData.entidades_importadas).join(',');
          this.arquivos = rData.arquivos;
          this.contadores = JSON.parse(rData.contadores);
          this.criado_em_por = `${rData.usuario} em ${rData.criado_em}`;
          this.log_validacoes_existe = rData.log_validacoes_existe;

          this.iniciarTourPagina();
        }
      );
    },

    baixarLogsValidacoes() {
      this.loadingLogsValidacoes = true;

      ImportacoesBdgdService.baixarLogsValidacoes(this.importacao.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `logs-validacoes-importacao-${this.importacao.id}.zip`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download dos logs de validações.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingLogsValidacoes = false));
    },

    baixarLogsTecnicos() {
      this.loadingLogsTecnicos = true;

      ImportacoesBdgdService.baixarLogsTecnicos(this.importacao.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `logs-tecnicos-importacao-${this.importacao.id}.log`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download dos logs técnicos.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingLogsTecnicos = false));
    },

    isEmptyObject(obj) {
      return JSON.stringify(obj) === '{}';
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      this.iniciarTour(this.arrTour);
    }
  }
};
</script>
